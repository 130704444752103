import {
	EntityDataModuleConfig,
	EntityFilterFn,
	EntityMetadataMap,
	PropsFilterFnFactory,
} from '@ngrx/data'
import { environmentSelectId } from './environment/environment.selectors'
import { viewCustomizationSelectId } from './view-customization/view-customization.selectors'
import { selectPersonId } from './person/person.selectors'
import { selectWorktimeGroupId } from './worktime-group/worktime-group.selectors'
import { selectWorktimeGroupHistoryId } from './worktime-group-history/worktime-group-history.selectors'
import { selectEventTypeId } from './event-type/event-type.selectors'
import {
	Dimension,
	PersonAttribute,
	WorktimeGroup,
	WorkShift,
	EventType,
	PersonPublicData,
} from './models'
import { Person, PersonTemplate } from './person/person.model'
import { selectPersonAttributeStoreId } from './person-attribute/person-attribute.selectors'
import { selectWorkShiftId } from './workshift/workshift.selectors'
import { WorktimeGroupHistory } from './worktime-group-history/worktime-group-history.model'

interface ExactFilter<T> {
	type: 'exact'
	value: Partial<T>
}
interface LikeFilter {
	type: 'like'
	value: string
}
export type FilterPattern<T> = ExactFilter<T> | LikeFilter

export function ExactFilterFnFactory<T>() {
	return (entities: T[], obj: Partial<T>) => {
		const properties = Object.keys(obj) as (keyof T)[]

		return entities.filter((attribute) =>
			properties.every((property) => attribute[property] === obj[property])
		)
	}
}

export function FilterFactory<T>(
	exactFilter: (entities: T[], pattern: Partial<T>) => T[],
	likeFilter: (entities: T[], pattern: string) => T[]
): EntityFilterFn<T> {
	return (entities: T[], pattern?: FilterPattern<T>) => {
		if (!pattern) return entities
		if (pattern.type === 'exact') return exactFilter(entities, pattern.value)
		if (pattern.type === 'like')
			return likeFilter(entities, pattern.value.toString())
		return entities
	}
}

/**
 * Add selectId function for all entities that are in management-section, even if the primary key is the default id-field.
 * This way the management section always uses the correct selectId-function when mapping entities to sidebarItems.
 * For more information and example, see management/configurations/person => mapItemToSidebarItem-selector
 *
 * Stored as a separate const so we can get the keys (EntityMetaDataKeys) as a type.
 */
export const entityMetadata = {
	User: {},
	ViewCustomization: {
		selectId: viewCustomizationSelectId,
	},
	CardWidget: {},
	ListWidget: {},
	AvailableCardWidget: {},
	AvailableListWidget: {},
	News: {},
	Holidays: {},
	WorktimeDay: {},
	WorktimeDayInfo: {},
	WorktimeWeek: {},
	ExceptionDays: {},
	CompanyLocations: {},
	Environment: {
		selectId: environmentSelectId,
	},
	WorktimeGroup: {
		selectId: selectWorktimeGroupId,
		filterFn: PropsFilterFnFactory<WorktimeGroup>(['description', 'name']),
		sortComparer: nameComparer,
	},
	WorktimeGroupHistory: {
		selectId: selectWorktimeGroupHistoryId,
		filterFn: PropsFilterFnFactory<WorktimeGroupHistory>(['personId']),
		sortComparer: startDateComparer,
	},
	Person: {
		selectId: selectPersonId,
		filterFn: PropsFilterFnFactory<Person>(['name', 'email', 'username']),
		sortComparer: nameComparer,
	},
	PersonPublicData: {
		selectId: (personPublicData) => personPublicData.id,
		filterFn: PropsFilterFnFactory<PersonPublicData>(['name', 'shortname']),
		sortComparer: nameComparer,
	},
	PersonTemplate: {
		selectId: selectPersonId,
		filterFn: PropsFilterFnFactory<PersonTemplate>(['name']),
		sortComparer: nameComparer,
	},
	EventType: {
		selectId: selectEventTypeId,
		filterFn: PropsFilterFnFactory<EventType>(['name']),
		sortComparer: nameComparer,
	},
	Event: {},
	PersonAttribute: {
		selectId: selectPersonAttributeStoreId,
		filterFn: FilterFactory<PersonAttribute>(
			ExactFilterFnFactory(),
			PropsFilterFnFactory(['description'])
		),
		sortComparer: descriptionComparer,
	},
	WorkShift: {
		selectId: selectWorkShiftId,
		filterFn: FilterFactory<WorkShift>(
			ExactFilterFnFactory(),
			PropsFilterFnFactory(['name'])
		),
		sortComparer: nameComparer,
	},
	Dimension: {
		filterFn: PropsFilterFnFactory<Dimension>(['name', 'dimensionId']),
		sortComparer: descriptionComparer,
	},
	DimensionLevel: {},
	SelectedDimension: {},
	StampingReason: {},
	Supervisors: {},
	Stamp: {},
	TimeCounterBalance: {},
} as const satisfies EntityMetadataMap

export type EntityName = keyof typeof entityMetadata

// override default plural names here (for example hero => heroes (instead of heros))
const pluralNames = {}

export const entityConfig: EntityDataModuleConfig = {
	entityMetadata,
	pluralNames,
}

// Helpers for providing sorting
interface WithDescription {
	description: string
}

function descriptionComparer(e1: WithDescription, e2: WithDescription) {
	if (e1.description === undefined && e2.description === undefined) return 0
	return e1.description.localeCompare(e2.description)
}

interface WithName {
	name: string
}

function nameComparer(e1: WithName, e2: WithName) {
	return e1.name.localeCompare(e2.name)
}

interface WithStartDate {
	startTime: string
}

function startDateComparer(e1: WithStartDate, e2: WithStartDate) {
	return e1.startTime.localeCompare(e2.startTime)
}
