import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LetDirective, PushPipe } from '@ngrx/component'
import { ENTITY_TYPE_MAP_TOKEN } from '../../entity-type.provider'
import { entityTypeGroupLabels } from '../../components/entity-type-list/entity-type-list.model'

@Component({
	standalone: true,
	imports: [CommonModule, LetDirective, PushPipe, RouterModule],
	selector: 'kk-management-entity-type-list',
	templateUrl: './entity-type-list.component.html',
})
export class ManagementEntityTypeListComponent {
	entityTypeMap = inject(ENTITY_TYPE_MAP_TOKEN)
	entityTypeGroups = entityTypeGroupLabels

	public readonly originalOrder = (): number => 0
}
