import { EntitySelectorsFactory } from '@ngrx/data'
import { createSelector } from '@ngrx/store'
import { ViewCustomization } from './view-customization.model'

export const viewCustomizationSelector =
	new EntitySelectorsFactory().create<ViewCustomization>('ViewCustomization')

export const viewCustomizationSelectId = (i: ViewCustomization) =>
	viewCustomizationCreateId(i.personId, i.guid, i.controlKey)

export const viewCustomizationCreateId = (
	personId: number,
	guid: string,
	controlKey: string
) => `${personId}/${guid}/${controlKey}`

export const selectViewCustomizations = createSelector(
	viewCustomizationSelector.selectEntities,
	(items) => items
)
