export enum FlexHandling {
	ReducesBalance = 'ReducesBalance',
	FillsWorkingDay = 'FillsWorkingDay',
	CanIncreaseBalance = 'CanIncreaseBalance',
}

export const flexHandlingLabels: { [key in FlexHandling]: string } = {
	ReducesBalance: $localize`Ei ole työaikaa (vähentää saldoa)`,
	FillsWorkingDay: $localize`Täyttää työpäivän (ei plussia)`,
	CanIncreaseBalance: $localize`Kasvattaa saldoa`,
}

export enum EventTypeCategory {
	Worktime = 'Worktime',
	Break = 'Break',
	SickLeave = 'SickLeave',
	AnnualLeave = 'AnnualLeave',
	HolidayPayLeave = 'HolidayPayLeave',
	FamilyLeave = 'FamilyLeave',
	TravelTime = 'TravelTime',
	OnCall = 'OnCall',
	Other = 'Other',
}

export const eventTypeCategoryLabels: { [key in EventTypeCategory]: string } = {
	Worktime: $localize`Työaika`,
	Break: $localize`Tauko`,
	SickLeave: $localize`Sairaus`,
	AnnualLeave: $localize`Vuosiloma`,
	HolidayPayLeave: $localize`Lomarahavapaa`,
	FamilyLeave: $localize`Perhevapaa`,
	TravelTime: $localize`Matka-aika`,
	OnCall: $localize`Varallaolo`,
	Other: $localize`Muu`,
}

export enum UsableOnHourDayEvent {
	UsableOnHourAndDayEvent = 'UsableOnHourAndDayEvent',
	UsableOnHourEvent = 'UsableOnHourEvent',
	UsableOnDayEvent = 'UsableOnDayEvent',
}

export const usableOnHourDayEventLables: {
	[key in UsableOnHourDayEvent]: string
} = {
	UsableOnHourAndDayEvent: $localize`Käytössä tunti- ja päiväkirjauksella`,
	UsableOnHourEvent: $localize`Käytössä tuntikirjauksella`,
	UsableOnDayEvent: $localize`Käytössä päiväkirjauksella`,
}

export interface EventType {
	id: number
	name: string
	orderNumber: number
	coffeeBreak: boolean
	color: string
	useBackgroundColor: boolean
	flexHandling: FlexHandling
	IgnoreStartEndTimesLimit: boolean
	maximumWorkingHours: string
	errorThreshold: string
	fillSaturdays: boolean
	/**
	 * 	Can contain letters
	 */
	monthlyFormOfSalaryNumber: string
	monthlyFormOfSalaryDescription: string
	/**
	 * 	Can contain letters
	 */
	hourlyFormOfSalaryNumber: string
	hourlyFormOfSalaryDescription: string
	hideFromClocking: boolean
	worktimeGroups: number[]
	eventTypeCategory: EventTypeCategory

	usableOnHourDayEventSelection: UsableOnHourDayEvent
}
