import { Injectable, Signal } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, filter, map, shareReplay } from 'rxjs'
import { User } from './user.model'
import { userApiActions } from './user.actions'
import { userFeature } from './user.feature'
import {
	selectCurrentUserAttributes,
	selectCurrentUserWorktimeGroup,
} from './user.selectors'
import { PersonAttributeService } from '../entity-services'
import { WorktimeGroup } from '../worktime-group/worktime-group.model'
import { overtimePracticeOptions } from '../worktime-group/overtime-practice.model'
import { personAttributeSelector } from '../person-attribute/person-attribute.selectors'
import { isEqual } from 'lodash-es'
import { Settings } from 'luxon'

@Injectable({ providedIn: 'root' })
export class UserService {
	attributes = this.store.selectSignal(selectCurrentUserAttributes, {
		equal: isEqual,
	})
	attributes$ = this.store.select(selectCurrentUserAttributes)
	attributesLoading: Signal<boolean> = this.store.selectSignal(
		personAttributeSelector.selectLoading
	)

	// WorktimeGroup and overtime Practices are required for logging hours. These should/could be
	// moved to another service, when for example logging hours for another user is possible
	worktimeGroup$ = this.store.select(selectCurrentUserWorktimeGroup)
	overtimePracticeOptions$ = this.worktimeGroup$.pipe(
		map((worktimeGroup) =>
			this.getAvailableOvertimePracticeOptions(worktimeGroup)
		)
	)

	constructor(
		private store: Store,
		private personAttributeService: PersonAttributeService
	) {}

	setLoading() {
		this.personAttributeService.setLoading(true)
	}

	getCurrentUser(): Observable<User> {
		this.store.dispatch(userApiActions.loadUser())
		const result$ = this.store
			.select(userFeature.selectUser)
			.pipe(filter(Boolean))
			.pipe(shareReplay())

		result$.subscribe((person) => (Settings.defaultZone = person.useTimeZone))

		return result$
	}

	getAvailableOvertimePracticeOptions(worktimeGroup: WorktimeGroup | null) {
		if (!worktimeGroup) return []
		if (worktimeGroup.overtimePracticeAll) return overtimePracticeOptions

		const options = overtimePracticeOptions.filter(
			(item) => worktimeGroup[item.worktimeGroupKey as keyof WorktimeGroup]
		)
		return options
	}
}
