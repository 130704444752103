import { EntityForm } from '../../models'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { Component, ChangeDetectionStrategy } from '@angular/core'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { EventTypeService } from 'src/app/core/ngrx-store/entity-services'
import { requiredTextField } from 'src/app/shared/components/form/formly-field'
import {
	EventType,
	EventTypeCategory,
	eventTypeCategoryLabels,
	UsableOnHourDayEvent,
} from 'src/app/core/ngrx-store/event-type/event-type.model'

@Component({
	standalone: true,
	selector: 'kk-event-type-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi kirjauslaji"
			i18n-title
		/>
	`,
})
export class EventTypeCreateNewContentComponent implements EntityForm {
	constructor(private eventTypeService: EventTypeService) {}

	model: Partial<EventType> = {
		name: '',
		eventTypeCategory: EventTypeCategory.Worktime,
		usableOnHourDayEventSelection: UsableOnHourDayEvent.UsableOnHourAndDayEvent,
	}

	fields: FormlyFieldConfig[] = [
		requiredTextField('name', $localize`Nimi`),
		{
			key: 'eventTypeCategory',
			type: 'select',
			className: 'g-col-12',
			props: {
				label: $localize`Kirjauslajityyppi`,
				options: Object.entries(eventTypeCategoryLabels).map(
					([key, label]) => ({
						value: key,
						label,
					})
				),
			},
		},
	]

	onSubmit() {
		this.eventTypeService.add(
			{
				worktimeGroups: [],
				color: '#7DB43E',
				...this.model,
			},
			{ isOptimistic: false }
		)
	}
}
