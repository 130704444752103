import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { WorkShift } from './workshift.model'
import { selectWorkShifts } from './workshift.selectors'
import { createSelector } from '@ngrx/store'
import { selectRouterEntityId } from '../router/router.selectors'
import { FilterPattern } from '../entity-metadata'
import { HttpClient } from '@angular/common/http'
import { Observable, shareReplay } from 'rxjs'

const selectWorkShiftsForWorktimeGroup = createSelector(
	selectWorkShifts,
	selectRouterEntityId,
	(workShifts, entityId) => {
		return workShifts.filter(
			(item) => item.worktimeGroupId === Number(entityId)
		)
	}
)

@Injectable()
export class WorkShiftService extends EntityCollectionServiceBase<WorkShift> {
	constructor(
		private http: HttpClient,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('WorkShift', serviceElementsFactory)
	}

	/**
	 *
	 * @returns Work shifts for the activated person group in management view
	 */
	workShiftsForWorktimeGroup$ = this.store.select(
		selectWorkShiftsForWorktimeGroup
	)

	getWorkShiftsForWorktimeGroup(
		worktimeGroupId: number
	): Observable<WorkShift[]> {
		return this.http.get<WorkShift[]>(
			`workshifts/getByWorktimeGroupId/` + worktimeGroupId
		)
	}

	override setFilter(pattern?: FilterPattern<WorkShift>): void {
		super.setFilter(pattern)
	}

	getWorkShiftForPunchIn(timestamp: string): Observable<number> {
		return this.http.get<number>(
			`features/workshiftselection/GetWorkShiftForPunchIn?timestamp=` +
				timestamp
		)
	}

	getWorkShiftForPunchOut(): Observable<number> {
		return this.http
			.get<number>(`features/workshiftselection/GetWorkShiftForPunchOut`)
			.pipe(shareReplay())
	}
}
