import { InjectionToken } from '@angular/core'
import { Feature } from './features'

export type FeatureFlags = Record<Feature, boolean>

export interface AppConfig {
	sentryEnvironment: string
	production: boolean
	sentryEnabled: boolean
	apiUrl: string
	features: FeatureFlags
	customUserImpersonationScope?: string
}

export const APP_CONFIG_TOKEN = new InjectionToken<AppConfig>('AppConfig')
