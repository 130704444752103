import { FormlyFieldConfig } from '@ngx-formly/core'
import { InjectionToken, Signal, Type } from '@angular/core'
import { MemoizedSelector, createSelector } from '@ngrx/store'
import {
	EntityActionOptions,
	EntityCache,
	EntityCollectionServiceBase,
	EntitySelectors,
} from '@ngrx/data'
import { PersonManagement } from './configurations/person/person'
import { selectRouterEntityId } from 'src/app/core/ngrx-store/router/router.selectors'
import {
	Dimension,
	DimensionLevel,
	WorktimeGroup,
	StampingReason,
	WorkShift,
} from 'src/app/core/ngrx-store/models'
import { Observable, map } from 'rxjs'
import { Dictionary } from '@ngrx/entity'
import { toSignal } from '@angular/core/rxjs-interop'
import { Feature } from 'src/app/core/utils/features'
import { EventType } from 'src/app/core/ngrx-store/event-type/event-type.model'

export interface EntityActionMenuOption {
	title: string
	component: Type<EntityForm>
}

/**
 * Configuration for management page entities.
 */
export interface EntityType {
	/**
	 * Localized title of the entity
	 */
	title: string
	/**
	 * Url path for this entity. Used for links in management view
	 * @see entityTypeMap in configurations.ts
	 */
	path: string
	/**
	 * Injection token for the entity service. 
	 * Links the correct ngrx-data service and its dependant configurations to the entity
	 * 
	 * @see EntityService
	 * 
	 * @example ```new InjectionToken<PersonService>('persons', {
		factory: () => inject(PersonService),
	}) ```
	 */
	serviceToken: InjectionToken<EntityService<Entity>>

	/*
	 * Component to be displayed as the modal content when creating a new entity.
	 * See https://angular.io/api/core/ComponentRef#componentType for "Type<any>" usage.
	 */
	createNewContentComponent: Type<EntityForm>
	actionMenuOptions?: EntityActionMenuOption[]
	/**
	 * List of feature flags that are required for this entity type to be accessible in the management view.
	 * You can configure these feature flags in the `AppConfig`'s `features` section.
	 */
	featureGate?: Feature[]
	useCustomView?: boolean
	hideDeleteButton?: boolean
	hideSubmitButton?: boolean
}

export interface SidebarItem {
	/**
	 * Primary key of the entity. Use a function from core/ngrx/{entity}/{entity}.selectors.
	 * This way changing the primary key of an entity does create bugs here.
	 */
	id: string
	title?: Observable<string | undefined>
	subtitle?: Observable<string | undefined>
	fields$?: Observable<string>[]
	children?: SidebarItem[]
	totalCount?: number
}

/**
 * Entity types that can be managed in Management pages.
 * Can be consturcted by joining multiple ngrx dataservices, use naming convention
 * with "Management"-suffix (for example personManagement = person + personAttribute).
 */
export type Entity =
	| WorktimeGroup
	| PersonManagement
	| EventType
	| WorkShift
	| Dimension
	| DimensionLevel
	| StampingReason

/**
 * Entity service types for entities that can be managed in Management pages
 *
 * @see Entity
 */

export interface EntityForm {
	model: object
	fields: FormlyFieldConfig[]
}

/**
 *  Type for the entity selectors.
 */
type EntitySelectorType<T, T2> =
	// the usual single entity select case
	| MemoizedSelector<
			EntityCache | Record<string, T>,
			T | null,
			(s1: Dictionary<T>, s2: string) => T | null
	  >
	// this is needed for the selector with additional data (for example person management).
	| MemoizedSelector<
			EntityCache,
			T | null,
			(s1: T | null, s2: T2[]) => T | null
	  >

export abstract class EntityService<T extends Entity, T2 = never> {
	constructor(
		entityCollectionService: EntityCollectionServiceBase<T>,
		selector: EntitySelectorType<T, T2>
	) {
		this.entityCollectionService = entityCollectionService
		this.sidebarItems$ = this.entityCollectionService.filteredEntities$.pipe(
			map((entities) => entities.map(this.mapEntityToSidebarItem))
		)
		this.entity = entityCollectionService.store.selectSignal(selector)
		this.loading = toSignal(entityCollectionService.loading$)
	}

	/**
	 * ngrx-data service for this entity
	 */
	entityCollectionService: EntityCollectionServiceBase<T>

	/**
	 * Currently selected entity. Define manually in every EntityService. Can be constructed from
	 * multiple NGRX dataservices with filters.
	 * @see PersonModel
	 */
	entity: Signal<T | null>

	sidebarView: 'list' | 'tree' = 'list'

	/**
	 *
	 */
	loading: Signal<boolean | undefined>

	/**
	 * Filtered sidebar items. Use `setFilter` to filter the collection.
	 */
	sidebarItems$: Observable<SidebarItem[]>

	/**
	 * Formly form configuration for this entity
	 */
	formlyFields: FormlyFieldConfig[]

	/**
	 * Function for mapping entity into sidebar item. This is called from the EntityService class constructor only.
	 */
	protected abstract mapEntityToSidebarItem: (entity: T) => SidebarItem

	/**
	 * Proxy for EntityCollectionService's setFilter. This is used to filter sideBarItems.
	 * @param pattern
	 */
	setFilter(pattern?: string | Partial<T>) {
		this.entityCollectionService.setFilter(pattern)
	}

	selectId(entity: T) {
		return this.entityCollectionService.selectId(entity)
	}

	getAll(options?: EntityActionOptions) {
		return this.entityCollectionService.getAll(options)
	}

	update(entity: Partial<T>, options?: EntityActionOptions) {
		return this.entityCollectionService.update(entity, options)
	}

	delete(id: number | string, options?: EntityActionOptions) {
		return this.entityCollectionService.delete(id, options)
	}
}

/**
 * @param {EntitySelector} selector - EntitySelector, for example:
 * new EntitySelectorsFactory().create<Person>("Person")
 * @returns - (Memoized selector) Selected Entity based on entityId from route
 */
export function entitySelectorFactory<T = Entity>(
	selector: EntitySelectors<T>
) {
	return createSelector(
		selector.selectEntityMap,
		selectRouterEntityId,
		(entityMap, id) => {
			return entityMap[id] ?? null
		}
	)
}
