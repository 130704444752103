import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
	MergeStrategy,
} from '@ngrx/data'
import { WorktimeDay } from './worktime-day.model'
import { HttpClient } from '@angular/common/http'
import { DateUtils } from '../../utils/date-utils'
import { ToastService, UserService } from '../entity-services'
import { OvertimePractice } from '../worktime-group/overtime-practice.model'
import { shareReplay } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class WorktimeDayService extends EntityCollectionServiceBase<WorktimeDay> {
	private _loadedMonths: { personId: number; year: number; month: number }[] =
		[]

	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
		userService: UserService,
		private httpClient: HttpClient,
		private toastService: ToastService
	) {
		super('WorktimeDay', serviceElementsFactory)
		userService.getCurrentUser().subscribe(() => {
			this._loadedMonths = []
		})
	}

	public setOvertimePracticeForDay(
		worktimeDay: WorktimeDay,
		overtimePractice: OvertimePractice
	) {
		this.httpClient
			.get(
				'WorktimeDay/setOvertimePracticeForDay/' +
					worktimeDay.id +
					'/' +
					overtimePractice
			)
			.pipe(shareReplay())
			.subscribe((result) => {
				if (result) {
					this.updateOneInCache({
						...worktimeDay,
						overtimePractice: overtimePractice,
					})
					this.toastService.showSuccess($localize`Ylituntitapa vaihdettu.`)
				} else
					this.toastService.showError(
						$localize`Ylituntitavan vaihto epäonnistui!`
					)
			})
	}

	public markAsAccepted(worktimeDay: WorktimeDay) {
		// Update instantly so ui feels more responsive
		this.updateOneInCache({
			...worktimeDay,
			isReady: true,
			isAccepted: true,
			isOpen: false,
			canCancelAcceptanceMark: false,
			canMarkAsAccepted: false,
			canCancelReadyMark: false,
			canMarkAsReady: false,
		})

		const request$ = this.httpClient
			.post<
				WorktimeDay | undefined
			>('WorktimeDay/markAsAccepted', worktimeDay.id)
			.pipe(shareReplay())

		request$.subscribe((workTimeDay) => {
			if (workTimeDay) {
				this.updateOneInCache(workTimeDay)
			}
		})

		return request$
	}

	public cancelReadyMark(worktimeDay: WorktimeDay) {
		// Update instantly so ui feels more responsive
		this.updateOneInCache({
			...worktimeDay,
			isReady: false,
			isAccepted: false,
			isOpen: true,
			canCancelAcceptanceMark: false,
			canMarkAsAccepted: false,
			canCancelReadyMark: false,
			canMarkAsReady: true,
		})

		const request$ = this.httpClient
			.post<
				WorktimeDay | undefined
			>('WorktimeDay/cancelReadyMark', worktimeDay.id)
			.pipe(shareReplay())

		request$.subscribe((workTimeDay) => {
			if (workTimeDay) {
				this.updateOneInCache(workTimeDay)
			}
		})

		return request$
	}

	public cancelAcceptanceMark(worktimeDay: WorktimeDay) {
		// Update instantly so ui feels more responsive
		this.updateOneInCache({
			...worktimeDay,
			isReady: false,
			isAccepted: true,
			isOpen: true,
			canCancelAcceptanceMark: false,
			canMarkAsAccepted: false,
			canCancelReadyMark: false,
			canMarkAsReady: false,
		})

		const request$ = this.httpClient
			.post<
				WorktimeDay | undefined
			>('WorktimeDay/cancelAcceptanceMark', worktimeDay.id)
			.pipe(shareReplay())

		request$.subscribe((workTimeDay) => {
			if (workTimeDay) {
				this.updateOneInCache(workTimeDay)
			}
		})

		return request$
	}

	public markAsReady(worktimeDay: WorktimeDay) {
		// Update instantly so ui feels more responsive.
		this.updateOneInCache({
			...worktimeDay,
			isReady: true,
			isAccepted: false,
			isOpen: false,
			canCancelAcceptanceMark: false,
			canMarkAsAccepted: false,
			canCancelReadyMark: true,
			canMarkAsReady: false,
		})

		const request$ = this.httpClient
			.post<WorktimeDay | undefined>('WorktimeDay/markAsReady', worktimeDay.id)
			.pipe(shareReplay())

		request$.subscribe((workTimeDay) => {
			if (workTimeDay) {
				this.updateOneInCache(workTimeDay)
			}
		})

		return request$
	}

	public reloadDays(personId: number, startDate: Date, endDate: Date) {
		const queryParams = {
			personId: personId,
			startDate: DateUtils.toIsoStringDatePartOnly(startDate),
			endDate: DateUtils.toIsoStringDatePartOnly(endDate),
		}

		const request$ = this.getWithQuery(queryParams, {
			mergeStrategy: MergeStrategy.OverwriteChanges,
		}).pipe(shareReplay())

		request$.subscribe((worktimeDays) => {
			this.updateManyInCache(worktimeDays)
		})
		return request$
	}

	public reloadDay(personId: number, date: Date) {
		return this.reloadDays(personId, date, date)
	}

	public loadMonth(personId: number, year: number, monthIndex: number) {
		if (
			this._loadedMonths.some(
				(i) =>
					i.personId === personId && i.year === year && i.month === monthIndex
			)
		) {
			return
		}

		this._loadedMonths.push({
			personId: personId,
			year: year,
			month: monthIndex,
		})

		const start = new Date(year, monthIndex, 1)
		const end = DateUtils.getLastDateOfMonth(start)
		const queryParams = {
			personId: personId,
			startDate: DateUtils.toIsoStringDatePartOnly(start),
			endDate: DateUtils.toIsoStringDatePartOnly(end),
		}

		this.getWithQuery(queryParams, {
			mergeStrategy: MergeStrategy.OverwriteChanges,
		})
			.pipe(shareReplay())
			.subscribe((worktimeDays) => {
				this.updateManyInCache(worktimeDays)
			})
	}
}
