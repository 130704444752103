import { FormlyFieldConfig } from '@ngx-formly/core'
import {
	DimensionLevelService,
	DimensionService,
} from 'src/app/core/ngrx-store/entity-services'
import { Dimension, DimensionLevel } from 'src/app/core/ngrx-store/models'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { EntityForm } from '../../models'
import { selectRouterEntityId } from 'src/app/core/ngrx-store/router/router.selectors'
import { map } from 'rxjs'
import { toSignal } from '@angular/core/rxjs-interop'

@Component({
	standalone: true,
	selector: 'kk-dimension-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi kohde"
			i18n-title
		/>
	`,
})
export class DimensionCreateNewContentComponent implements EntityForm {
	constructor(
		private dimensionService: DimensionService,
		private dimensionLevelService: DimensionLevelService
	) {}

	activeEntityId =
		this.dimensionService.store.selectSignal(selectRouterEntityId)()
	model: Partial<Dimension> = {}

	dimensionLevels = toSignal(this.dimensionLevelService.entities$)

	fields: FormlyFieldConfig[] = [
		{
			key: 'dimensionLevelId',
			type: 'select',
			props: {
				label: $localize`Kohdetaso`,
				required: true,
				options: this.dimensionLevelService.entities$.pipe(
					map((dimensionLevels: DimensionLevel[]) =>
						dimensionLevels.map((dimensionLevel) => ({
							value: dimensionLevel.id,
							label: dimensionLevel.description,
						}))
					)
				),
				attributes: {
					'data-testid': 'dimensionLevelSelect',
				},
			},
			hooks: {
				onInit: (field) => {
					this.dimensionLevelService.entities$.subscribe((dimensionLevels) => {
						field.formControl?.setValue(dimensionLevels[0].id)
					})
				},
			},
		},
		{
			key: 'parentDimensionId',
			type: 'select',
			props: {
				label: $localize`Valitse pääkohde`,
			},
			expressions: {
				'props.options': (field: FormlyFieldConfig) => {
					// Get dimensions from parent level
					const dimensions$ = this.dimensionService
						.getParentDimensionsByDimensionLevelId(
							field.model?.dimensionLevelId
						)
						.pipe(
							map((dimensions: Dimension[] | undefined) =>
								dimensions?.map((dimension) => ({
									value: dimension.id,
									label: dimension.dimensionId + ' ' + dimension.name,
								}))
							)
						)

					// Set first dimension as selected value by default
					if (field.formControl?.value === undefined)
						dimensions$.subscribe((dimensions) => {
							if (dimensions && dimensions?.length > 0)
								field.formControl?.setValue(dimensions[0].value)
						})

					// Return dimensions
					return dimensions$
				},
				'props.required': (field) =>
					this.dimensionLevels()?.find(
						(dl) => dl.id === field.model?.dimensionLevelId
					)?.parentId !== null,

				hide: (field) =>
					this.dimensionLevels()?.find(
						(dl) => dl.id === field.model?.dimensionLevelId
					)?.parentId === null,
			},
		},
		{
			key: 'dimensionId',
			type: 'input',
			className: 'g-col-12',
			props: {
				label: $localize`Kohteen id/tunnus`,
				required: true,
			},
		},
		{
			key: 'name',
			type: 'input',
			props: {
				label: 'Kohteen nimi',
				required: true,
			},
		},
	]

	onSubmit() {
		// Add dimension to service (save) and refresh dimension cache after subscribe
		this.dimensionService
			.add(
				{
					...this.model,
					worktimeGroups: [],
					active: true,
				},
				{ isOptimistic: false }
			)
			.subscribe((dimension) => {
				if (dimension !== undefined)
					this.dimensionService.addDimensionsForDimensionLevel(
						dimension.dimensionLevelId,
						dimension
					)
			})
	}
}
