import { Injectable } from '@angular/core'
import { SelectedDimension } from './selected-dimension.model'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { forkJoin, Observable, of, shareReplay } from 'rxjs'

@Injectable()
export class SelectedDimensionService extends EntityCollectionServiceBase<SelectedDimension> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('SelectedDimension', serviceElementsFactory)
	}

	getSelectedDimensions(
		selectedDimensionIds: number[] | undefined
	): Observable<SelectedDimension[] | undefined> {
		if (!selectedDimensionIds || selectedDimensionIds.length === 0)
			return of([])

		const requests = selectedDimensionIds.map((id) =>
			this.getByKey(id).pipe(shareReplay())
		)

		return forkJoin(requests)
	}
}
