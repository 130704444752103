import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { WorktimeDayInfo } from './worktime-day-info.model'
import { HttpClient } from '@angular/common/http'
import { map, Observable, of, shareReplay } from 'rxjs'
import { DateUtils } from '../../utils/date-utils'

@Injectable({ providedIn: 'root' })
export class WorktimeDayInfoService extends EntityCollectionServiceBase<WorktimeDayInfo> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
		private httpClient: HttpClient
	) {
		super('WorktimeDayInfo', serviceElementsFactory)
	}

	private _loadedDays: { personId: number; date: Date }[] = []

	/**
	 * Load Day info for whole week by date
	 * @param personId Person id
	 * @param searchDate Search date
	 */
	loadWorktimeDayInfoForDay(
		personId: number | undefined,
		searchDate: Date
	): void {
		if (personId === undefined) return

		// Different person, clear cache
		if (this._loadedDays.some((i) => i.personId !== personId)) {
			this.clearCache()
		}

		// If day info is already loaded, no need to reload
		if (
			this._loadedDays.some(
				(i) =>
					i.personId === personId && i.date.getTime() === searchDate.getTime()
			)
		) {
			return
		}

		// Get monday and sunday
		const startDate = DateUtils.getMondayOfWeek(searchDate)
		const endDate = DateUtils.getSundayOfWeek(searchDate)

		// Add all days to cache
		for (
			let d = new Date(startDate);
			d <= endDate;
			d.setDate(d.getDate() + 1)
		) {
			this._loadedDays.push({ personId: personId, date: new Date(d) })
		}

		// Load data and save to cache
		this.httpClient
			.get<WorktimeDayInfo[]>(
				'worktimeday/getdayinfo/' +
					personId +
					'/' +
					DateUtils.formatUTCStringTocalString(
						startDate.toISOString(),
						'yyyy-MM-dd'
					) +
					'/' +
					DateUtils.formatUTCStringTocalString(
						endDate.toISOString(),
						'yyyy-MM-dd'
					)
			)
			.pipe(shareReplay())
			.subscribe((worktimeDayInfos) => this.addManyToCache(worktimeDayInfos))
	}

	/**
	 * Get worktime day info from cache to a certain date
	 * @param date Date
	 * @returns Worktime day info
	 */
	getWorktimeDayInfo(
		date: Date | undefined
	): Observable<WorktimeDayInfo | undefined> {
		if (date === undefined) return of(undefined)
		return this.entities$.pipe(
			map((infos) =>
				infos.find((i) => new Date(i.date).getTime() === date.getTime())
			)
		)
	}

	/**
	 * Clear cache and clear _loadedDays-record
	 */
	override clearCache(): void {
		this._loadedDays = []
		super.clearCache()
	}
}
