import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
	MergeStrategy,
} from '@ngrx/data'
import {
	TimeCounterBalance,
	TimeCounterType,
} from './time-counter-balance.model'
import { UserService } from '../entity-services'
import { WorktimeWeek } from '../models'

@Injectable()
export class TimeCounterBalanceService extends EntityCollectionServiceBase<TimeCounterBalance> {
	private _loadedBalances: {
		personId: number
		weekNumber: number
		timeCounterType?: TimeCounterType
		balanceId?: number
		date: string
	}[] = []

	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
		userService: UserService
	) {
		super('TimeCounterBalance', serviceElementsFactory)
		userService.getCurrentUser().subscribe(() => {
			this._loadedBalances = []
		})
	}

	/*
	 * Load all time counter balances starting from the given date
	 */
	async loadByWeek(worktimeWeek: WorktimeWeek) {
		const queryParams = {
			personId: worktimeWeek.personId,
			date: worktimeWeek.startDate,
		}
		await this.removeElementsFromCache(worktimeWeek)
		this.getWithQuery(queryParams, {
			mergeStrategy: MergeStrategy.OverwriteChanges,
		}).subscribe((balances) => {
			this.processBalances(balances, worktimeWeek)
		})
	}

	/*
	 * Load weekly hours for the given week
	 */
	async getWeeklyHours(worktimeWeek: WorktimeWeek) {
		if (
			this._loadedBalances.find(
				(b) =>
					b.weekNumber === worktimeWeek.weekNumber &&
					b.personId === worktimeWeek.personId
			)
		) {
			return
		} else {
			await this.loadByWeek(worktimeWeek)
		}
	}

	/*
	 * Remove all elements from cache that are newer than given date
	 */
	async removeElementsFromCache(worktimeWeek: WorktimeWeek) {
		const removeIds: number[] = this._loadedBalances
			.filter(
				(loadedWeek) =>
					new Date(loadedWeek.date) >= new Date(worktimeWeek.startDate) &&
					loadedWeek.balanceId !== undefined
			)
			.map((loadedWeek) => loadedWeek.balanceId!)
			.filter((id): id is number => id !== undefined)

		this._loadedBalances = this._loadedBalances.filter(
			(loadedWeek) =>
				new Date(loadedWeek.date) < new Date(worktimeWeek.startDate)
		)

		await this.removeManyFromCache(removeIds)
	}

	/*
	 * Process loaded balances and save them to the cache
	 */
	private processBalances(
		balances: TimeCounterBalance[],
		worktimeWeek: WorktimeWeek
	) {
		this._loadedBalances.push(
			...balances.map((balance) => ({
				personId: worktimeWeek.personId,
				weekNumber: balance.balanceWeekNumber,
				timeCounterType: balance.timeCounterType,
				balanceId: balance.id,
				date: balance.balanceDate,
			}))
		)
	}
}
