import { EntitySelectorsFactory } from '@ngrx/data'
import { createSelector } from '@ngrx/store'
import { WorktimeGroupHistory } from './worktime-group-history.model'
import { selectRouterEntityId } from '../router/router.selectors'
import { DateTime } from 'luxon'

export const worktimeGroupHistorySelector =
	new EntitySelectorsFactory().create<WorktimeGroupHistory>(
		'WorktimeGroupHistory'
	)

export const selectPerselectWorktimeGroupHistoryIdsonGroupId = (
	worktimeGroupHistory: WorktimeGroupHistory
) => worktimeGroupHistory.id

export const selectWorktimeGroupHistoryId = (
	worktimeGroupHistory: WorktimeGroupHistory
) => worktimeGroupHistory.id
export const selectPersonsGroupHistoryByPersonId = (
	worktimeGroupHistory: WorktimeGroupHistory
) => worktimeGroupHistory.personId

export const selectWorktimeGroupHistoryForPerson = createSelector(
	worktimeGroupHistorySelector.selectEntities,
	selectRouterEntityId,
	(worktimeGroupHistories: WorktimeGroupHistory[], routerentityId: string) => {
		return worktimeGroupHistories
			.filter((item) => Number(item.personId) === Number(routerentityId))
			.map((item) => ({
				...item,
				startTime: DateTime.fromISO(item.startTime, { zone: 'utc' }).toISO(),
			}))
			.sort((a, b) => {
				const aStart = a.startTime ?? ''
				const bStart = b.startTime ?? ''
				if (aStart < bStart) return 1
				if (aStart > bStart) return -1
				return 0
			})
	}
)
