import { Injectable } from '@angular/core'
import {
	EntityActionOptions,
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
	MergeStrategy,
} from '@ngrx/data'
import { Observable, shareReplay } from 'rxjs'
import { ChangeStamp, Event } from './event.model'
import { HttpClient } from '@angular/common/http'
import { userFeature } from '../user/user.feature'
import { DateUtils } from '../../utils/date-utils'
import { UserService } from '../entity-services'

@Injectable()
export class EventService extends EntityCollectionServiceBase<Event> {
	private _loadedMonths: {
		personId: number
		year: number
		month: number
	}[] = []

	constructor(
		private http: HttpClient,
		userService: UserService,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('Event', serviceElementsFactory)

		userService.getCurrentUser().subscribe(() => {
			this._loadedMonths = []
		})
	}

	getStampInStatus(): Observable<boolean> {
		const user = this.store.selectSignal(userFeature.selectUser)
		return this.http
			.get<boolean>(`events/stamp-in-status/` + user()?.id)
			.pipe(shareReplay())
	}

	getOpenEvent(): Observable<Event | null> {
		const user = this.store.selectSignal(userFeature.selectUser)
		return this.http
			.get<Event>(`events/get-open-event/` + user()?.id)
			.pipe(shareReplay())
	}

	override delete(
		entity: Event,
		options?: EntityActionOptions
	): Observable<number | string>
	override delete(
		key: number | string,
		options?: EntityActionOptions
	): Observable<number | string>
	override delete(
		parameter1: Event | number | string,
		options?: EntityActionOptions
	): Observable<string | number> {
		let request$: Observable<string | number>
		let event: Event

		if (typeof parameter1 === 'string' || typeof parameter1 === 'number') {
			throw new Error('Not supported')
		} else {
			event = parameter1
			request$ = super.delete(parameter1, options)
		}

		request$.subscribe(() => {
			this.loadPreviousAndNextEvents(event.personId, event.start)
		})

		return request$
	}

	override add(
		entity: Partial<Event>,
		options: EntityActionOptions & { isOptimistic: false }
	): Observable<Event>
	override add(entity: Event, options?: EntityActionOptions): Observable<Event>
	override add(
		entity: Partial<Event> | Event,
		options?:
			| EntityActionOptions
			| (EntityActionOptions & { isOptimistic: false })
	): Observable<Event> {
		entity.latestModification = undefined
		let request$: Observable<Event>
		if (options?.isOptimistic === false) {
			request$ = super.add(
				entity as Partial<Event>,
				options as EntityActionOptions & { isOptimistic: false }
			)
		} else {
			request$ = super.add(entity as Event, options)
		}

		request$.subscribe(() => {
			if (entity.personId && entity.start)
				this.loadPreviousAndNextEvents(entity.personId, entity.start)
		})
		return request$
	}

	override update(
		entity: Partial<Event>,
		options?: EntityActionOptions
	): Observable<Event> {
		const request$ = super.update(entity, options)

		request$.subscribe((updatedEvent) => {
			this.loadPreviousAndNextEvents(updatedEvent.personId, updatedEvent.start)
		})

		return request$
	}

	loadMonthlyEvents(currentDate: Date, personId: number) {
		const year = currentDate.getFullYear()
		const monthIndex = currentDate.getMonth()

		if (
			this._loadedMonths.some(
				(i) =>
					i.personId === personId && i.year === year && i.month === monthIndex
			)
		) {
			return
		}

		this._loadedMonths.push({
			personId: personId,
			year: year,
			month: monthIndex,
		})

		const { startDate, endDate } = this.calculateDateRange(currentDate)
		this.loadEvents(personId, startDate, endDate)
	}

	private loadPreviousAndNextEvents(personId: number, eventStart: string) {
		const start = new Date(eventStart)
		start.setDate(start.getDate() - 2)

		const end = new Date(eventStart)
		end.setDate(end.getDate() + 3)

		return this.loadEvents(personId, start, end)
	}

	public loadEventsByDate(personId: number, date: Date) {
		return this.loadEvents(personId, date, date)
	}

	public loadEventsByWeek(personId: number, date: Date) {
		const start = DateUtils.getMondayOfWeek(date)
		start.setDate(start.getDate() - 1) // add one day just incase of timezones

		const end = DateUtils.getSundayOfWeek(date)
		end.setDate(start.getDate() + 1)

		return this.loadEvents(personId, start, end)
	}

	private loadEvents(
		personId: number,
		start: Date,
		end: Date
	): Observable<Event[]> {
		const queryParams = {
			personId: personId,
			startDate: DateUtils.formatDateWithoutTimezone(start),
			endDate: DateUtils.formatDateWithoutTimezone(end),
		}
		const request$ = this.getWithQuery(queryParams, {
			mergeStrategy: MergeStrategy.OverwriteChanges,
		}).pipe(shareReplay())

		request$.subscribe({
			error: () => (this._loadedMonths = []),
		})

		return request$
	}

	/**
	 * Calculates the date range for the given current date.
	 * The range starts from the 1st day of the previous month and ends on the last day of the next month.
	 *
	 * @param {Date} currentDate - The current date for which the range is calculated.
	 * @returns {Object} An object containing the startDate and endDate.
	 */
	calculateDateRange(currentDate: Date): {
		startDate: Date
		endDate: Date
	} {
		const previousMonthDate = new Date(currentDate)
		previousMonthDate.setMonth(currentDate.getMonth() - 1)
		const startOfMonth = DateUtils.getFirstDateOfMonth(previousMonthDate)

		const nextMonthDate = new Date(currentDate)
		nextMonthDate.setMonth(currentDate.getMonth() + 1)
		const endOfMonth = DateUtils.getLastDateOfMonth(nextMonthDate)

		return {
			startDate: startOfMonth,
			endDate: endOfMonth,
		}
	}

	override clearCache(): void {
		this._loadedMonths = []
		super.clearCache()
	}

	splitEvent(event: Event, splitDate: string): Observable<Event[]> {
		const request$ = this.http
			.post<Event[]>('events/split-event/' + splitDate, event)
			.pipe(shareReplay())
		request$.subscribe((events) => {
			this.upsertManyInCache(events, {
				mergeStrategy: MergeStrategy.OverwriteChanges,
			})
		})
		return request$
	}

	changeStamp(changeStampEvents: Partial<ChangeStamp>): Observable<Event[]> {
		const request$ = this.http
			.post<Event[]>('events/change-stamp', changeStampEvents)
			.pipe(shareReplay())
		request$.subscribe((events) => {
			this.upsertManyInCache(events, {
				mergeStrategy: MergeStrategy.OverwriteChanges,
			})
		})
		return request$
	}
}
