import { Event } from 'src/app/core/ngrx-store/models'
import { MbscCalendarEvent } from '@mobiscroll/angular'
import { HistoryEvent } from 'src/app/core/ngrx-store/history-event/history-event.model'

export enum RangeType {
	Agenda = 'agenda',
	Day = 'day',
	Week = 'week',
	Month = 'month',
}

export type DateRange = [Date, Date]

export class CalendarEvent implements MbscCalendarEvent, Event {
	id: number
	personId: number
	start: string
	end: string
	header: string
	isReadOnly?: boolean
	isSpecialDay?: boolean
	allDay?: boolean
	color?: string
	overlap?: boolean
	realizedWorkshiftDate: string
	workshiftId: number
	workshiftContinues: boolean
	eventTypeId: number
	useBackgroundColor: boolean
	isBillable: boolean
	stampInId?: number
	stampInReasonId?: number
	stampOutReasonId?: number
	stampOutId?: number
	freeText: string
	cutTimeBegin: number
	cutTimeLunch: number
	cutTimeBreak: number
	cutTimeEnd: number
	durationCutToShift: number
	bufferAfter?: number | undefined
	canDelete: boolean
	latestModification?: HistoryEvent
	selectedDimensions?: number[]
	constructor(init: Partial<CalendarEvent>) {
		Object.assign(this, {
			color: '#7DB43E', // Set default color for events. This will be defined by EventType
			...init,
		})
	}
	get title() {
		return this.header
	}
}

export class AgendaDay {
	events: AgendaEvent[]
	timestamp: number
}

export class AgendaEvent {
	startDate: Date
	endDate: Date
	original?: CalendarEvent
}

export interface SpecialDay {
	id: number
	date: string
	description: string
	specialDayType: number
}

export class CalendarTexts {
	public static readonly updateBalancesButtonText = $localize`Päivitä saldot`
	public static readonly markCurrentWeekAsReadyButtonText = $localize`Merkitse valmiiksi`
	public static readonly cancelCurrentWeekReadyMarkButtonText = $localize`Peru valmismerkintä`
	public static readonly markCurrentWeekAsAcceptedButtonText = $localize`Merkitse hyväksytyksi`
	public static readonly cancelCurrentWeekAcceptedMarkButtonText = $localize`Peru hyväksyntä`

	public static readonly readyCheckText = $localize`Merkitse valmiiksi`
	public static readonly cancelReadyCheckText = $localize`Peru valmismerkintä`
	public static readonly acceptedCheckText = $localize`Hyväksy`
	public static readonly cancelAcceptedCheckText = $localize`Peru hyväksyntä`
	public static readonly workshiftText = $localize`Työvuoro`
	public static readonly eventTypeText = $localize`Kirjauslaji`
	public static readonly workshiftDateText = $localize`Työvuoropvm`
	public static readonly isBillableText = $localize`Laskutetaan`
	public static readonly freeTextText = $localize`Vapaa`
	public static readonly cutTimeBeginText = $localize`Pyöristys alussa`
	public static readonly cutTimeLunchText = $localize`Lounasvähennys`
	public static readonly cutTimeBreakText = $localize`Taukoa leikattu`
	public static readonly cutTimeEndText = $localize`Pyöristys lopussa`
	public static readonly durationText = $localize`Kesto`
	public static readonly latestModificationText = $localize`Viimeisin muokkaus`
	public static readonly dayInfoText = $localize`Päivän tiedot`
	public static readonly worktimeGroupText = $localize`Työaikaryhmä`
	public static readonly plannedWorktimeText = $localize`Suunniteltu työaika`
	public static readonly loggedWorktimeText = $localize`Kirjattu työaika`
	public static readonly lunchReductionText = $localize`Ruokatuntivähennys kirjauksista`
	public static readonly realizedWorktimeText = $localize`Päivän toteutunut työaika`
	public static readonly stampHistoryText = $localize`Leimausloki`
	public static readonly changeText = $localize`muutos`
	public static readonly endOfDayText = $localize`päivän lopussa`
}
