import { DateSelectionType } from '../../utils/date-selection-type'
import { DayOfWeek } from '../../utils/day-of-week'
import { SpecialDayType } from '../../utils/special-day-type'
import { OvertimePractice } from '../worktime-group/overtime-practice.model'

export enum AutoPunchOutPractice {
	NoAutomaticPunchOut = 'NoAutomaticPunchOut',
	ByWorkShiftDuration = 'ByWorkShiftDuration',
	ToPlannedEndTime = 'ToPlannedEndTime',
	ToPlannedEndTimePlusLunch = 'ToPlannedEndTimePlusLunch',
}

export const autoPunchOutPracticeLabels: {
	[key in AutoPunchOutPractice]: string
} = {
	NoAutomaticPunchOut: $localize`Ei automaattista ulosleimausta`,
	ByWorkShiftDuration: $localize`Työvuoron keston mukaan`,
	ToPlannedEndTime: $localize`Työvuoron suunniteltuun loppuaikaan`,
	ToPlannedEndTimePlusLunch: $localize`Työvuoron suunniteltuun loppuaikaan plus ruokatunti`,
}

export interface WorkShift {
	id: number
	name: string
	number: number
	inuseBit: boolean
	startTime: string // DateTime
	endTime: string // DateTime
	workshiftDuration?: number
	start_SelectionZone_Before?: number
	start_SelectionZone_After?: number
	workShiftEndTime?: number
	defaultWorkShift: boolean
	hasPlan: boolean
	validityStartDate?: string // DateTime
	validityEndDate?: string // DateTime
	dateSelectionType: DateSelectionType
	dateSelection_Date?: string // DateTime
	dateSelection_Days: DayOfWeek[]
	dateSelection_SpecialDays: SpecialDayType[]
	priority: number
	forcedDaysOfWeek: DayOfWeek[]
	forcedExceptionDays: SpecialDayType[]
	notUsedInWorkShiftUtils: boolean
	roundingLimitBeforeStart?: number
	roundingLimitAfterStart?: number
	roundingLimitBeforeEnd?: number
	roundingLimitAfterEnd?: number
	//lunchReductionMethod: LunchReductionMethod
	//lunchForcedReductionDirection: LunchForcedReductionDirection
	lunchReductionWorkShiftMinimumDuration: number
	lunchMinimumDuration: number
	lunchReductionMinutes: number
	lunchReductionPosition: number
	lunchStartTime: string // DateTime
	breakMaxDuration?: number
	breakMaxCount?: number
	worktimeGroupId: number
	payslipCode?: string
	defaultEventType: number
	normalDuration?: string // DateTime
	OvertimePracticeDefault: OvertimePractice
	workdayEnd: string // DateTime
	AutomaticPunchOut: AutoPunchOutPractice
	lunchReductionWithoutStampMinutes: string // DateTime
	FlextimeBegin: string // DateTime
	FlextimeEnd: string // DateTime
	FlextimeEnabledOnOvertime: boolean
}
