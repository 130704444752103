import { Injectable, InjectionToken, inject } from '@angular/core'
import { dimensionSelector } from 'src/app/core/ngrx-store/dimension/dimension.selectors'
import { DimensionService } from 'src/app/core/ngrx-store/entity-services'
import { Dimension } from 'src/app/core/ngrx-store/models'
import {
	EntityService,
	EntityType,
	entitySelectorFactory,
	SidebarItem,
} from '../../models'
import { DimensionCreateNewContentComponent } from './dimension-create-new.component'
import { UrlMatchResult, UrlSegment } from '@angular/router'
import { Observable, of } from 'rxjs'
import { EntityTypeGroup } from '../../components/entity-type-list/entity-type-list.model'

export const selectDimensionEntity = entitySelectorFactory(dimensionSelector)

/**
 * Url Matcher for the dimension entityType, replaces default path-field implementation.
 * Maps 'dimensions' as entityTypePath-parameter.
 * This allows managementFeatureGuard and ngrx routerStore to detect dimensions as entityType url parameter.
 * @see DimensionEntitySelectionComponent
 * @see managementRoutes
 * @see selectRouterEntityTypePath
 * @see managementFeatureGuard
 */
export function dimensionsUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
	if (url[0].path === 'dimensions' && url.length === 1) {
		return {
			consumed: url,
			posParams: {
				entityTypePath: new UrlSegment('dimensions', {}),
			},
		}
	}
	return null
}

@Injectable({ providedIn: 'root' })
export class DimensionEntityService extends EntityService<Dimension> {
	override mapEntityToSidebarItem = () => ({}) as SidebarItem
	constructor(dimensionService: DimensionService) {
		super(dimensionService, selectDimensionEntity)
	}

	override getAll(): Observable<Dimension[]> {
		return of([])
	}
}

export const dimensionEntityType: EntityType = {
	mainGroup: EntityTypeGroup.Dimension,
	title: $localize`Kohteet`,
	path: 'dimensions',
	serviceToken: new InjectionToken<DimensionEntityService>('dimensions', {
		factory: () => inject(DimensionEntityService),
	}),
	createNewContentComponent: DimensionCreateNewContentComponent,
	useCustomView: true,
	hideDeleteButton: true,
	hideSubmitButton: true,
	featureGate: ['management-dimensions'],
}
export interface DimensionSearchFilter {
	searchTerm: string
}
