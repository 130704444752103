import { AppConfig } from 'src/app/core/utils/app-config'

export const environment: AppConfig = {
	sentryEnvironment: 'qa',
	production: false,
	sentryEnabled: true,
	apiUrl: 'https://kkv2-kellokortti.test.lemonsoft.io',
	features: {
		'management-stamping-reason': true,
		'management-dimensions': true,
		'calendar-stamping-reason': true,
	},
	customUserImpersonationScope:
		'https://LemonsoftB2C.onmicrosoft.com/GetAccessToken_testsite/user_impersonation',
}
