import { EntitySelectorsFactory } from '@ngrx/data'
import { Dimension } from './dimension.model'
import { createSelector } from '@ngrx/store'

export const dimensionSelector = new EntitySelectorsFactory().create<Dimension>(
	'Dimension'
)

export const selectDimensions = createSelector(
	dimensionSelector.selectEntities,
	(dimension) => dimension
)
