<div
	class="d-flex flex-column h-100 px-3 my-3 my-lg-4 justify-content-start"
	data-testid="ManagementSidebarEntityTypeGroup"
>
	<div
		*ngFor="let entityTypeGroup of entityTypeGroups | keyvalue: originalOrder"
	>
		<h2 class="mt-3 mb-3">{{ entityTypeGroup.value }}</h2>
		<div *ngFor="let entityType of entityTypeMap | keyvalue: originalOrder">
			@if (entityType.value.mainGroup === entityTypeGroup.key) {
				<div class="list-group" data-testid="ManagementSidebarEntityTypeList">
					<a
						class="list-group-item list-group-item-action p-0"
						[routerLink]="['.', entityType.key]"
						data-testid="ManagementSidebarEntityTypeItem"
					>
						<div
							class="w-100 p-3 pe-4 d-flex flex-row align-items-center justify-content-between"
						>
							<p>{{ entityType.value.title }}</p>
							<i class="fa-light fa-chevron-right fs-5"></i>
						</div>
					</a>
				</div>
			}
		</div>
	</div>
</div>
