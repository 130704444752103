import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode,
	HttpErrorResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, catchError, throwError, EMPTY } from 'rxjs'
import { SessionService } from '../../services/session/session.service'
import { AuthService } from '../../services/auth/auth.service'

/**
 * Error interceptor
 * Intercepts errors in api calls. If error is unauthorized => refresh token and try again.
 * This interceptor is not tested manually yet, since adding "bad" license token seems to just cause different error.
 */
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
	constructor(
		private sessionService: SessionService,
		private authService: AuthService
	) {}

	intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			catchError((error) => {
				const environment = this.sessionService.environment()
				const isError = error instanceof HttpErrorResponse
				const isUnauthorized = error.status === HttpStatusCode.Unauthorized

				if (isError && isUnauthorized && environment) {
					// Unauthorized error
					this.authService.logout()
					return EMPTY
				} else {
					//Unkown error
					return throwError(() => error)
				}
			})
		)
	}
}
