import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Supervisor } from './supervisor.model'
import { Observable, of } from 'rxjs'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class SupervisorService extends EntityCollectionServiceBase<Supervisor> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
		private http: HttpClient
	) {
		super('Supervisors', serviceElementsFactory)
	}

	hasSubordinates(personId: number | undefined): Observable<boolean> {
		if (personId === undefined) return of(false)

		return this.http.get<boolean>('supervisors/hasSubordinates/' + personId)
	}
}
