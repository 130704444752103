import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'

import { WorktimeGroup } from './worktime-group.model'

@Injectable()
export class WorktimeGroupService extends EntityCollectionServiceBase<WorktimeGroup> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('WorktimeGroup', serviceElementsFactory)
	}
}
