export enum EntityTypeGroup {
	Person = 'Person',
	Worktime = 'Worktime',
	Dimension = 'Dimension',
	General = 'General',
}

export const entityTypeGroupLabels: {
	[key in EntityTypeGroup]: string
} = {
	Person: $localize`Henkilöt`,
	Worktime: $localize`Työajanseuranta`,
	Dimension: $localize`Seurantakohteet`,
	General: $localize`Yleiset`,
}
