import { createSelector } from '@ngrx/store'
import { DateTime } from 'luxon'
import { RangeType } from './models'
import {
	routerSelectDate,
	routerSelectPersonId,
	routerSelectRangeType,
} from 'src/app/core/ngrx-store/router/router.selectors'

export const selectDate = createSelector(routerSelectDate, (date) => {
	const selectedDate = getDateFromParam(date as string) ?? new Date()
	// Set calendar start time to 7:00
	selectedDate.setHours(7, 0, 0, 0)
	return selectedDate
})

export const selectRangeType = createSelector(routerSelectRangeType, (view) => {
	return getRangeTypeFromParam(view as string) ?? RangeType.Week
})

export const getDateFromParam = (dateParam?: string) => {
	const dateTime = DateTime.fromFormat(dateParam ?? '', 'yyyy-MM-dd')

	if (dateTime.isValid) {
		return dateTime.toJSDate()
	} else {
		return null
	}
}

export const getRangeTypeFromParam = (rangeTypeParam?: string) => {
	if (
		rangeTypeParam &&
		Object.values(RangeType).includes(rangeTypeParam as RangeType)
	) {
		return rangeTypeParam as RangeType
	} else {
		return null
	}
}

export const selectPersonId = createSelector(routerSelectPersonId, (value) => {
	return value ? parseInt(value as string) : undefined
})
