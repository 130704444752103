import { Injectable } from '@angular/core'
import {
	EntityActionOptions,
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Person, PersonTemplate } from './person.model'
import { filter, Observable, of, Subject, switchMap } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { userApiActions } from '../user/user.actions'
import { userFeature } from '../user/user.feature'
import { PersonPublicData } from './person-public-data.model'

@Injectable()
export class PersonService extends EntityCollectionServiceBase<Person> {
	private worktimePersons$ = new Subject<Person[]>()
	private worktimePersonsLoaded = false

	constructor(
		private http: HttpClient,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('Person', serviceElementsFactory)
	}

	/**
	 * Returns all person whose worktimes can be accessed
	 */
	public getWorktimePersons(): Observable<Person[]> {
		if (!this.worktimePersonsLoaded) {
			this.worktimePersonsLoaded = true
			this.http
				.get<Person[]>('persons/worktimePersons')
				.subscribe((persons) => {
					this.addManyToCache(persons)
					this.worktimePersons$.next(persons)
				})
		}

		return this.worktimePersons$
	}

	public getCurrentUserPerson(): Observable<Person> {
		this.store.dispatch(userApiActions.loadUser())
		return this.store
			.select(userFeature.selectUser)
			.pipe(filter(Boolean))
			.pipe(switchMap((user) => this.getByKey(user.id)))
	}

	/**
	 * Remove supervisor from subordinates in cache
	 *
	 * @param supervisor Person number who used to be supervisor.
	 * @returns void
	 */
	public removeSupervisorFromPersonsCache(
		supervisor: number | null | undefined
	) {
		if (supervisor === null || supervisor === undefined) return

		this.entities$.subscribe((persons) => {
			persons
				.filter((p) => p.supervisor === supervisor)
				.forEach((p) => {
					const personToUpdate: Person = {
						...p,
						supervisor: 0,
					}
					this.updateOneInCache(personToUpdate)
				})
		})
	}
}

@Injectable()
export class PersonTemplateService extends EntityCollectionServiceBase<PersonTemplate> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('PersonTemplate', serviceElementsFactory)
	}
}

@Injectable()
export class PersonPublicDataService extends EntityCollectionServiceBase<PersonPublicData> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('PersonPublicData', serviceElementsFactory)
	}
	override getByKey(
		key: any,
		options?: EntityActionOptions
	): Observable<PersonPublicData> {
		return this.entities$.pipe(
			switchMap((entities: PersonPublicData[]) => {
				const personPublicData = entities.find(
					(p: PersonPublicData) => p.id === key
				)
				if (!personPublicData) {
					return super.getByKey(key, options)
				} else {
					return of(personPublicData)
				}
			})
		)
	}
}
