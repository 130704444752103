import { inject } from '@angular/core'
import { catchError, exhaustMap, map, of, retry, tap } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { UserDataService } from './user.data-service'
import { userApiActions } from './user.actions'
import { PersonAttributeService } from '../entity-services'

export const loadCurrentUser = createEffect(
	(actions$ = inject(Actions), service = inject(UserDataService)) => {
		return actions$.pipe(
			ofType(userApiActions.loadUser),
			exhaustMap(() =>
				service.getCurrentUser().pipe(
					map((user) => userApiActions.userLoadedSuccess({ user })),
					retry({ count: 2, delay: 200 }),
					catchError((error) => of(userApiActions.userLoadedFailure({ error })))
				)
			)
		)
	},
	{ functional: true }
)

export const loadCurrentUserPersonAttributes = createEffect(
	(actions$ = inject(Actions), service = inject(PersonAttributeService)) => {
		return actions$.pipe(
			ofType(userApiActions.userLoadedSuccess),
			tap((action) => {
				service.getWithQuery({
					personId: action.user.id,
					showAll: true,
				})
			})
		)
	},
	{ functional: true, dispatch: false }
)
