/// <reference types="@angular/localize" />

import {
	APP_INITIALIZER,
	ErrorHandler,
	importProvidersFrom,
} from '@angular/core'
import { AppComponent } from './app/app.component'
import { AppStoreModule } from 'src/app/core/ngrx-store/app-store.module'
import { AppRoutingModule } from './app/app-routing.module'
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser'
import {
	BAInterceptor,
	BrowserAuthModule,
	BARedirectComponent,
} from '@lemonsoft/browser-auth'
import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withInterceptors,
	withInterceptorsFromDi,
} from '@angular/common/http'
import { EnvironmentInterceptor } from './app/core/interceptors/environment/environment.interceptor'
import { UnauthorizedInterceptor } from './app/core/interceptors/error/unauthorized.interceptor'
import { localeFi, setOptions } from '@mobiscroll/angular'
import { APP_CONFIG_TOKEN } from './app/core/utils/app-config'
import { environment } from './environments/environment'
import { baseUrlInterceptor } from './app/core/interceptors/base-url/base-url.interceptor'
import { provideAnimations } from '@angular/platform-browser/animations'
import * as Sentry from '@sentry/angular'
import { Router } from '@angular/router'

setOptions({
	locale: localeFi,
	theme: 'ios',
	themeVariant: 'light',
	clickToCreate: true,
	dragToCreate: true,
	dragToMove: false,
	dragToResize: false,
	eventDelete: false,
})

Sentry.init({
	dsn: 'https://2a2a52aa3d41c14ecf8029e67cce38c6@o1135210.ingest.us.sentry.io/4508177622106112',
	environment: environment.sentryEnvironment,
	integrations: [],
	enabled: environment.sentryEnabled,
})

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			BrowserAuthModule.forRoot(
				'884efa7d-eefc-4dc0-83d4-1334fbbaf018',
				'LemonsoftB2C',
				'/',
				undefined,
				environment.customUserImpersonationScope
			),
			AppRoutingModule,
			AppStoreModule
		),
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{
			provide: APP_CONFIG_TOKEN,
			useValue: environment,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BAInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: EnvironmentInterceptor,
			multi: true,
		},
		provideHttpClient(
			withInterceptors([baseUrlInterceptor]),
			withInterceptorsFromDi()
		),
		provideAnimations(),
	],
})
	.then((app) => app.bootstrap(BARedirectComponent))
	.catch((err) => console.error(err))
