import { Injectable } from '@angular/core'
import {
	EntityActionOptions,
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
	QueryParams,
} from '@ngrx/data'
import { WorktimeGroupHistory } from './worktime-group-history.model'
import { Observable } from 'rxjs'

@Injectable()
export class WorktimeGroupHistoryService extends EntityCollectionServiceBase<WorktimeGroupHistory> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('WorktimeGroupHistory', serviceElementsFactory)
	}

	override loadWithQuery(
		queryParams: QueryParams | string,
		options?: EntityActionOptions
	): Observable<WorktimeGroupHistory[]> {
		this.clearCache()
		return super.loadWithQuery(queryParams, options)
	}
}
