import { DayOfWeek } from '../../utils/day-of-week'
import { OvertimePractice } from './overtime-practice.model'

export enum WorktimeCalculationPractice {
	PlannedTime = 'PlannedTime',
	RealizedTime = 'RealizedTime',
	NotCalculatedForEmptyDays = 'NotCalculatedForEmptyDays',
}

export const worktimeCalculationPracticeLabels: {
	[key in WorktimeCalculationPractice]: string
} = {
	PlannedTime: $localize`Suunniteltu työaika`,
	RealizedTime: $localize`Toteutunut työaika`,
	NotCalculatedForEmptyDays: $localize`Ei laskentaa tyhjille päiville`,
}

export enum WorkshiftSelectionPractice {
	WorkShiftPlan = 'WorkShiftPlan',
	TimeBased = 'TimeBased',
	PersonalWorkShifts = 'PersonalWorkShifts',
}

export const workshiftSelectionPracticeLabels: {
	[key in WorkshiftSelectionPractice]: string
} = {
	WorkShiftPlan: $localize`Työvuorosuunnitelmasta`,
	TimeBased: $localize`Kellonajan perusteella`,
	PersonalWorkShifts: $localize`Työvuorosuunnittelusta`,
}

export enum AcceptanceMode {
	Supervisor = 'Supervisor',
}

export const acceptanceModeLabels: {
	[key in AcceptanceMode]: string
} = {
	Supervisor: $localize`Esihenkilöhyväksyntä`,
}

export enum DimensionDisplayMode {
	ShowAndOrderById = 'ShowAndOrderById',
}

export const dimensionDisplayModeLabels: {
	[key in DimensionDisplayMode]: string
} = {
	ShowAndOrderById: $localize`Näytetään tunnus, järjestys tunnuksen mukaan`,
}

export interface WorktimeGroup {
	id: number
	name: string
	description: string
	overtimePracticeAll: boolean
	overtimePracticeFlex: boolean
	overtimePracticePaid: boolean
	overtimePracticeVacation: boolean
	overtimePracticeIgnore: boolean
	overtimePracticeHourByHour: boolean
	overtimePracticeDefault: OvertimePractice
	workshiftSelectionOrder: WorkshiftSelectionPractice[]
	breakMaxLength: number
	revalidateWorkshiftOnPunchOut: boolean
	automaticReadyCheck: DayOfWeek
	flextimeEnabledForGroup: boolean
	flextimeMaximumBalance: number | null
	flextimeMinimumBalanceChange: number | null
	flextimeIgnoreNegativeChanges: boolean
	flextimeIgnoreEmptyWorkshifts: boolean

	// Stamp in/out visibility settings
	showStampInOutWidget: boolean
	askHeaderOnStampIn: boolean
	askHeaderOnStampOut: boolean
	askWorkshiftOnStampIn: boolean
	askWorkshiftOnStampOut: boolean
	askEventTypeOnStampIn: boolean
	askEventTypeOnStampOut: boolean
	askStampingReasonOnStampOut: boolean
	askStampingReasonOnStampIn: boolean

	// Event visibility settings
	askHeaderOnEvent: boolean
	askBillabilityOnEvent: boolean
	askFreetextOnEvent: boolean
	askWorkshiftOnEvent: boolean

	// Acceptance mode settings
	readyCheckInUse: boolean
	acceptanceMode: AcceptanceMode

	// Dimensions
	dimensionDisplayMode: DimensionDisplayMode
	dimensionLevels: number[]
}
