import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { WorktimeWeek } from '../models'
import { Observable, shareReplay } from 'rxjs'

@Injectable()
export class StampHandlerService {
	constructor(private http: HttpClient) {}

	updateBalances(worktimeWeek: WorktimeWeek): Observable<Date | null> {
		return this.http
			.get<Date | null>(
				`features/stamphandler/RunStampHandlerForPerson/` +
					worktimeWeek.personId +
					`/` +
					worktimeWeek.startDate
			)
			.pipe(shareReplay())
	}
}
